import React, { useState, useEffect } from "react";
import Menu from "../componentes/menu";
import { useNavigate } from "react-router-dom";
import { API_URL, SALT, MSG_ERROR_COMUNICACION } from "../constants";
import bcrypt from "bcryptjs";
import TabsAdmin from "../componentes/tabsAdmin";

function Perfil() {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const [mensajeError, setMensajeError] = useState("");
  const [mensajeErrorColor, setMensajeErrorColor] = useState("");

  const [nombreUsuario, setNombreUsuario] = useState("");
  const [email, setEmail] = useState("");

  const [passwordAnterior, setPasswordAnterior] = useState("");
  const [passwordNueva, setPasswordNueva] = useState("");
  const [passwordNuevaRepetida, setPasswordNuevaRepetida] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [errorPasswordColor, setErrorPasswordColor] = useState("");

  const [textoDisabled, setTextoDisabled] = useState(false);
  const [errorSolicitud, setErrorSolicitud] = useState("");
  const [errorSolicitudColor, setErrorSolicitudColor] = useState("");
  const [solicitud, setSolicitud] = useState("");
  const [textoBotonSolicitud, setTextoBotonSolicitud] = useState("Solicitar");

  const [textoTelegram, setTextoTelegram] = useState("");
  const [colorTelegram, setColorTelegram] = useState("");
  const [codigoTelegram, setCodigoTelegram] = useState("");
  const [vinculadoTelegram, setVinculadoTelegram] = useState("");
  const [notificacionesTelegram, setNotificacionesTelegram] = useState("");

  const [votosBuenosDisponibles, setVotosBuenosDisponibles] = useState("");
  const [votosBuenosDados, setVotosBuenosDados] = useState("");
  const [votosMalosDisponibles, setVotosMalosDisponibles] = useState("");
  const [votosMalosDados, setVotosMalosDados] = useState("");
  const [votosBueno, setVotosBueno] = useState([]);
  const [votosMalo, setVotosMalo] = useState([]);

  const [admin, setAdmin] = useState(false);

  // Mandamos al usuario al login si no está logueado
  useEffect(() => {
    if (!token) {
      navigate("/login");
      return;
    }
  }, [navigate, token]);

  // Traer los datos del perfil
  useEffect(() => {
    async function obtenerPerfil() {
      setMensajeError("");
      setMensajeErrorColor("");

      try {
        const response = await fetch(API_URL + "/perfil", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        });

        if (response.status === 200) {
          // Obtenemos body
          const body = await response.json();

          setNombreUsuario(body.nombre);
          setEmail(body.email);
          setVotosBuenosDisponibles(body.votosBuenosDisponibles);
          setVotosBuenosDados(body.votosBuenosDados);
          setVotosMalosDisponibles(body.votosMalosDisponibles);
          setVotosMalosDados(body.votosMalosDados);
          setVotosBueno(body.votosBueno);
          setVotosMalo(body.votosMalo);
        } else {
          
          setMensajeError("Error al obtener perfil");
          setMensajeErrorColor("colorRojo");

          localStorage.removeItem("token");
          navigate("/login");
        }
      } catch (error) {
        setMensajeError(MSG_ERROR_COMUNICACION);
        setMensajeErrorColor("colorRojo");
        console.error(MSG_ERROR_COMUNICACION, error);

        localStorage.removeItem("token");
        navigate("/login");
      }
    }
    obtenerPerfil();
  }, [token, navigate]);

  useEffect(() => {
    async function comprobarAdmin() {
      setMensajeError("");
      setMensajeErrorColor("");

      try {
        const response = await fetch(API_URL + "/admin", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        });

        if (response.status === 200) {
          console.log("Usuario administrador");
          setAdmin(true);
        } else {
          console.log("Usuario base");
          setAdmin(false);
        }
      } catch (error) {
        setMensajeError(MSG_ERROR_COMUNICACION);
        setMensajeErrorColor("colorRojo");
        console.error(MSG_ERROR_COMUNICACION, error);
      }
    }
    comprobarAdmin();
  }, [token]);

  useEffect(() => {
    async function comprobarSolicitud() {
      setErrorSolicitud("");
      setErrorSolicitudColor("");

      try {
        const response = await fetch(API_URL + "/cambionombre", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        });

        if (response.status === 200) {
          const body = await response.json();
          setSolicitud(body.nombre);
          setTextoBotonSolicitud(body.estado);
          setTextoDisabled(true);
        } else if (response.status === 404) {
          setTextoBotonSolicitud("Solicitar");
        } else {
          const body = await response.text();
          setErrorSolicitud(body);
          setErrorSolicitudColor("colorRojo");
        }
      } catch (error) {
        setErrorSolicitud(MSG_ERROR_COMUNICACION);
        setErrorSolicitudColor("colorRojo");
        console.error(MSG_ERROR_COMUNICACION, error);
      }
    }
    comprobarSolicitud();
  }, [token]);

  useEffect(() => {
    async function infoTelegram() {
      setTextoTelegram("");
      setColorTelegram("");

      try {
        const response = await fetch(API_URL + "/infotelegram", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        });

        if (response.status === 200) {
          const data = await response.json();

          setCodigoTelegram(data.codigo);

          if (data.vinculado) {
            setVinculadoTelegram("Sí");
          } else {
            setVinculadoTelegram("No");
          }

          if (data.notificaciones) {
            setNotificacionesTelegram("Sí");
          } else {
            setNotificacionesTelegram("No");
          }
        } else {
          const body = await response.text();
          setTextoTelegram(body);
          setColorTelegram("colorRojo");
        }
      } catch (error) {
        console.error(MSG_ERROR_COMUNICACION, error);
        setTextoTelegram(MSG_ERROR_COMUNICACION);
        setColorTelegram("colorRojo");
      }
    }
    infoTelegram();
  }, [token]);

  const cambioPassword = async () => {
    setErrorPassword("");
    setErrorPasswordColor("");

    const hashOldPassword = bcrypt.hashSync(passwordAnterior, SALT);
    const hashNewPassword = bcrypt.hashSync(passwordNueva, SALT);

    if (
      passwordNueva === "" ||
      passwordNuevaRepetida === "" ||
      passwordAnterior === ""
    ) {
      setErrorPassword("Completa los campos");
      setErrorPasswordColor("colorRojo");
    }

    if (passwordNueva !== passwordNuevaRepetida) {
      setErrorPassword("La nueva contraseña no coincide");
      setErrorPasswordColor("colorRojo");
    }

    if (passwordNueva.length < 4) {
      setErrorPassword("Mínimo cuatro caracteres");
      setErrorPasswordColor("colorRojo");
    }

    try {
      const response = await fetch(API_URL + "/cambiarpassword", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          oldPassword: hashOldPassword,
          newPassword: hashNewPassword,
        }),
      });

      const data = await response.text();
      if (response.status === 200) {
        setErrorPassword(data);
        setErrorPasswordColor("colorVerde");
      } else {
        setErrorPassword(data);
        setErrorPasswordColor("colorRojo");
      }
    } catch (error) {
      setErrorPassword(MSG_ERROR_COMUNICACION);
      setErrorPasswordColor("colorRojo");
      console.error(MSG_ERROR_COMUNICACION, error);
    }
  };

  const solicitarNombre = async () => {
    setErrorSolicitud("");
    setErrorSolicitudColor("");

    try {
      const response = await fetch(API_URL + "/cambionombre", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          nombre: solicitud,
        }),
      });

      const data = await response.text();
      if (response.status === 200) {
        setErrorSolicitud(data);
        setErrorSolicitudColor("colorVerde");
        setTextoBotonSolicitud("Enviado");
        setTextoDisabled(true);
      } else {
        setErrorSolicitud(data);
        setErrorSolicitudColor("colorRojo");
      }
    } catch (error) {
      console.log(MSG_ERROR_COMUNICACION, error);
      setErrorSolicitud(MSG_ERROR_COMUNICACION);
      setErrorSolicitudColor("colorRojo");
    }
  };

  const generarCodigoTelegram = async () => {

    setTextoTelegram("");
    setColorTelegram("");

    try {
      const response = await fetch(API_URL + "/codigovinculacion", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      const data = await response.text();
      if (response.status === 200) {
        setCodigoTelegram(data);
      } else {
        setTextoTelegram(data);
        setColorTelegram("colorRojo");
      }
    } catch (error) {
      console.log(MSG_ERROR_COMUNICACION, error);
      setTextoTelegram(MSG_ERROR_COMUNICACION);
      setColorTelegram("colorRojo");
    }

  };

  const alternarNotificaciones = async () => {

    setTextoTelegram("");
    setColorTelegram("");

    try {

      const response = await fetch(API_URL + "/notificaciones", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      const data = await response.text();
      if (response.status === 200) {
        setTextoTelegram(data);
        setColorTelegram("colorVerde");
        
        if (notificacionesTelegram === "Sí") {
          setNotificacionesTelegram("No");
        } else {
          setNotificacionesTelegram("Sí");
        }

      } else {
        setTextoTelegram(data);
        setColorTelegram("colorRojo");
      }
    } catch (error) {
      console.log(MSG_ERROR_COMUNICACION, error);
      setTextoTelegram(MSG_ERROR_COMUNICACION);
      setColorTelegram("colorRojo");
    }

  };

  const desvincularTelegram = async () => {

    setTextoTelegram("");
    setColorTelegram("");

    try {

      const response = await fetch(API_URL + "/desvinculartelegram", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      const data = await response.text();
      if (response.status === 200) {
        setTextoTelegram(data);
        setColorTelegram("colorVerde");

        if (vinculadoTelegram === "Sí") {
          setVinculadoTelegram("No");
          setNotificacionesTelegram("No");
        } else {
          setVinculadoTelegram("Sí");
        }
      } else {
        setTextoTelegram(data);
        setColorTelegram("colorRojo");
      }
    } catch (error) {
      console.log(MSG_ERROR_COMUNICACION, error);
      setTextoTelegram(MSG_ERROR_COMUNICACION);
      setColorTelegram("colorRojo");
    }

  };

  return (
    <div className="Padre">
      <div className="ContenedorNav">
        <Menu></Menu>
      </div>

      <div className="Cuerpo">
        {true && <div className="margenPerfil"> </div>}

        <div className="ContenedorPerfil">
          <h1 className="titulo1 centro">MI PERFIL</h1>
          <p className={`mensajeError centro ${mensajeErrorColor}`}>
            {mensajeError}
          </p>

          <div className="SeccionPerfil">
            <h2>Datos usuario</h2>
            <p className="CampoPerfil">Nombre: {nombreUsuario}</p>
            <p className="CampoPerfil">E-mail: {email}</p>
          </div>

          <div className="espacioperfil"></div>

          <div className="SeccionPerfil">
            <h2>Cambiar contraseña</h2>
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <input
                className="campoLogin"
                autoComplete="current-password"
                type="password"
                placeholder="Contraseña anterior"
                value={passwordAnterior}
                onChange={(e) => setPasswordAnterior(e.target.value)}
              />
              <input
                className="campoLogin"
                type="password"
                autoComplete="new-password"
                placeholder="Contraseña nueva"
                value={passwordNueva}
                onChange={(e) => setPasswordNueva(e.target.value)}
              />
              <input
                className="campoLogin"
                type="password"
                autoComplete="new-password"
                placeholder="Repetir contraseña nueva"
                value={passwordNuevaRepetida}
                onChange={(e) => setPasswordNuevaRepetida(e.target.value)}
              />
              <button className="BotonCambiarPassword" onClick={cambioPassword}>
                Cambiar contraseña
              </button>
            </form>
            <p className={`mensajeError ${errorPasswordColor}`}>
              {errorPassword}
            </p>
          </div>

          <div className="espacioperfil"></div>

          <div className="SeccionPerfil">
            <h2>Solicitar nombre</h2>
            <input
              className="campoLogin"
              type="text"
              placeholder="Nuevo nombre"
              value={solicitud}
              disabled={textoDisabled}
              onChange={(e) => setSolicitud(e.target.value)}
            />

            <button
              className="BotonCambiarPassword"
              disabled={textoDisabled}
              onClick={solicitarNombre}
            >
              {textoBotonSolicitud}
            </button>
            <p className={`mensajeError ${errorSolicitudColor}`}>
              {errorSolicitud}
            </p>
          </div>

          <div className="espacioperfil"></div>

          <div className="SeccionPerfil">
            <h2>Notificaciones</h2>
            <div className="SeccionPerfil">
              <p>
                Recibe notificaciones vía telegram cada domingo para no perderte
                ningún voto.
              </p>
              <p>
                Envía tu código de vinculación a{" "}
                <a
                  className="linkTelegram"
                  target="_blank"
                  rel="noreferrer"
                  href="https://t.me/AmigosDLABot"
                >
                  @AmigosDLABot
                </a>
              </p>
            </div>

            <div className="espacioperfil"></div>

            <p className={`centro ${colorTelegram}`}>{textoTelegram}</p>

            <div className="SeccionPerfil">
              <p>Código de vinculación</p>
              <input
                className="campoLogin"
                type="text"
                placeholder="Código de vinculación"
                value={codigoTelegram}
                disabled
              />

              <button
                className="BotonActualizar BotonGenerar"
                onClick={generarCodigoTelegram}
              >
                Generar
              </button>
            </div>

            <div className="espacioperfil"></div>

            <div className="SeccionPerfil">
              <p className="CampoPerfil">
                Vinculado a telegram: {vinculadoTelegram}
              </p>
              <button className="BotonActualizar BotonGenerar"
              onClick={desvincularTelegram}>
                Desvincular
              </button>
            </div>

            <div className="espacioperfil"></div>

            <div className="SeccionPerfil">
              <p className="CampoPerfil">
                Notificaciones activas: {notificacionesTelegram}
              </p>
              <button className="BotonActualizar BotonGenerar"
              onClick={alternarNotificaciones}>
                Activar / desactivar
              </button>
            </div>
          </div>

          <div className="espacioperfil"></div>
          <div className="espacioperfil"></div>

          <div className="SeccionPerfil">
            <h2 className="mas">Votos al bueno del mes</h2>
            <p className="CampoPerfil">
              Votos disponibles: {votosBuenosDisponibles}
            </p>
            <p className="CampoPerfil">
              Votos dados en total {votosBuenosDados}
            </p>
          </div>

          <div className="espacioperfil"></div>

          {votosBueno.length > 0 && (
            <div className="SeccionPerfil">
              <h2 className="mas">Votos al bueno dados este mes</h2>

              <table className="TablaRanking">
                <thead>
                  <tr>
                    <th>Usuario</th>
                    <th>Fecha</th>
                  </tr>
                </thead>
                <tbody>
                  {votosBueno.map((voto, index) => (
                    <tr key={index}>
                      <td>{voto.usuarioVotado}</td>
                      <td>{voto.fecha}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          <div className="espacioperfil"></div>
          <div className="espacioperfil"></div>

          <div className="SeccionPerfil">
            <h2 className="menos">Votos al malo del mes</h2>
            <p className="CampoPerfil">
              Votos disponibles: {votosMalosDisponibles}
            </p>
            <p className="CampoPerfil">
              Votos dados en total {votosMalosDados}
            </p>
          </div>

          <div className="espacioperfil"></div>

          {votosMalo.length > 0 && (
            <div className="SeccionPerfil">
              <h2 className="menos">Votos al malo dados este mes</h2>

              <table className="TablaRanking">
                <thead>
                  <tr>
                    <th>Usuario</th>
                    <th>Fecha</th>
                  </tr>
                </thead>
                <tbody>
                  {votosMalo.map((voto, index) => (
                    <tr key={index}>
                      <td>{voto.usuarioVotado}</td>
                      <td>{voto.fecha}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>

        <div className="espaciomini"></div>

        {admin && (
          <div className="ContenedorPerfil">
            <TabsAdmin />
          </div>
        )}
      </div>
    </div>
  );
}

export default Perfil;
