import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { API_URL, MSG_ERROR_COMUNICACION } from "../constants";

function Menu() {
  const token = localStorage.getItem("token");
  const nombreGuardado = localStorage.getItem("nombre");
  const [nombre, setNombre] = useState("");

  useEffect(() => {
    async function obtenerNombre() {
      if (token) {
        //Pedimos nombre del usuario

        if (nombreGuardado) {
          setNombre(nombreGuardado);
          return;
        }

        try {
          const response = await fetch(API_URL + "/nombre", {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          });

          if (response.status === 200) {
            const data = await response.text();
            localStorage.setItem("nombre", data);
            setNombre(data);
          } else {
            setNombre("Error!");
          }
        } catch (error) {
          console.error(MSG_ERROR_COMUNICACION, error);
        }
      }
    }
    obtenerNombre();
  }, [token, nombreGuardado]);

  const cerrarSesion = () => {
    // Borramos el token
    localStorage.removeItem("token");
    localStorage.removeItem("nombre");
  };

  return (
    <div className="nav">
      <div className="logo">
        <h1>
          <Link to="/" className="h1nav">
            AMIGOS DE LA AMISTAD <sup className="version">V 2.3</sup>
          </Link>
        </h1>
      </div>
      <ul className="nav-links">
        <li>
          <Link to="/ranking">Ranking</Link>
        </li>
        <li>
          <Link to="/votar">Votar hoy</Link>
        </li>
        <ul className="nav-user">
          <li className="usuario-link">
            {token ? (
              // Si hay un usuario autenticado, muestra su nombre y un botón de salir
              <>
                <Link to="/perfil">Hola, {nombre}. </Link>

                <Link onClick={cerrarSesion} to="/">
                  Salir
                </Link>
              </>
            ) : (
              // Si no hay usuario autenticado, muestra el botón de login
              <Link to="/login">Login / Registro</Link>
            )}
          </li>
        </ul>
      </ul>
    </div>
  );
}

export default Menu;
